import axios from 'axios'

const ON_DEMAND_BASE_PATH = 'https://cloud-dashboard.api.d.pma.nflux.ai'

export const demandDevResolutionForUnit = async (unit: string): Promise<void> => {
  const { data } = await axios.post(`${ON_DEMAND_BASE_PATH}/dev_resolutions`, {
    unit,
  })

  return data
}
