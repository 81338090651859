
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import useSteps from '@/models/list/steps'
import { AiUnit } from '@/api/units'
import { api } from '@/main'
import { useVModels } from '@vueuse/core'
// import useDevRes from '@/models/get/dev_resolution'
import useDevResolutions from '@/models/list/dev_resolutions'
import { AiStep } from '@/api/dev_resolution'

export default defineComponent({
  name: 'UnitPopup',
  props: {
    unit: {
      required: true,
      type: Object as PropType<AiUnit>,
    },
    showModal: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const {
      showModal: show,
    } = useVModels(props, emit)
    const { fetchItems: fetchSteps, items: foundSteps } = useSteps()
    const { fetchItems: fetchDevSteps, items: foundDevSteps } = useSteps()
    const { fetchItems: fetchDevRes, items: foundDevRes } = useDevResolutions()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const [, sessionId,, unitId] = props.unit.name!.split('/')
    const multipleUnits = ref(false)
    const earlyStart = ref(false)
    const earlyStop = ref(false)
    const additionalNote = ref('')
    const correctionDev = ref<AiStep[]>([])
    const OutOfOrder = computed(() => Object.fromEntries(
      foundDevSteps.value
        .filter((v, i, a) => a.map(vv => vv.numberMacro).indexOf(v.numberMacro) === i)
        .map(v => [
          v.numberMacro,
          foundDevSteps.value
            .filter(vv => vv.numberMacro === v.numberMacro && vv.numberMacro !== 0 && vv.numberMacro !== -1 && (vv.status === 'COMPLETE' || vv.status === 'ACTIVE') && vv.numberMicro !== 0)
            .map(v => v.numberMicro),
        ]),
    ))
    const Missing = computed(() => Object.fromEntries(
      foundDevSteps.value
        .filter((v, i, a) => a.map(vv => vv.numberMacro).indexOf(v.numberMacro) === i)
        .map(v => [
          v.numberMacro,
          foundDevSteps.value
            .filter(vv => vv.numberMacro === v.numberMacro && vv.numberMacro !== 0 && vv.numberMacro !== -1 && vv.status === 'INCOMPLETE' && vv.numberMicro !== 0)
            .map(v => v.numberMicro),
        ]),
    ))
    const steps = computed(() => Object.fromEntries(
      foundSteps.value
        .filter((v, i, a) => a.map(vv => vv.numberMacro).indexOf(v.numberMacro) === i)
        .map(v => [
          v.numberMacro,
          foundSteps.value
            .filter(vv => vv.numberMacro === v.numberMacro && vv.numberMacro !== 0 && vv.numberMacro !== -1 && vv.numberMicro !== 0)
            .map(v => v.numberMicro),
        ]),
    ))

    const onSubmit = async () => {
      // todo update the database source without hardcode
      const cnt = ref(0)
      for (let i = 0; i < manual.value.length - 1; i++) {
        if (manual.value[i][1] !== 0 || manual.value[i][2] !== 0) {
          cnt.value += 1
        }
      }
      const newUnit = {
        ...props.unit,
        deviation: (cnt.value !== 0),
        deviationCount: cnt.value,
      }
      for (let i = 0; i < (manual.value.length); i++) {
        const step = {
          stepId: String(i),
          notDeviated: manual.value[i][0],
          outOfOrder: manual.value[i][1],
          missing: manual.value[i][2],
        }
        correctionDev.value.push(step)
      }
      // console.log(correctionDev.value)
      const newDevRes = {
        multipleUnits: multipleUnits.value,
        earlyStart: earlyStart.value,
        earlyStop: earlyStop.value,
        unit: props.unit.name,
        procedure: props.unit.procedure,
        additionalNote: additionalNote.value,
        steps: correctionDev.value,
      }
      if (foundDevRes.value[0]) {
        await api.devResolutions.devResolutionsDeleteDevResolution({ devresolution: foundDevRes.value[0].name?.split('/')[1] || '' })
      }
      await api.devResolutions.devResolutionsCreateDevResolution({ body: newDevRes })
      show.value = false
      // console.log(newUnit)
      const sessionId = (props.unit.name ?? '').split('/')[1]
      const unitId = (props.unit.name ?? '').split('/')[3]
      const options = {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Grpc-Metadata-Database': 'nokia',
        },
      }
      try {
        await api.units.unitsGetUnit({ session: sessionId, unit: unitId }, options)
        await api.units.unitsDeleteUnit({ session: sessionId, unit: unitId }, options)
        // await api.units.unitsGetUnit({ session: sessionId, unit: unitId }, { headers: { 'Grpc-Metadata-Database': 'nokia' } })
        // await api.units.unitsDeleteUnit({ session: sessionId, unit: unitId }, { headers: { 'Grpc-Metadata-Database': 'nokia' } })
      } catch (err) {
        console.log(err)
      }
      await api.units.unitsCreateUnit({ session: sessionId, body: newUnit }, options)
    }
    const store = ref<number[][]>([])
    const manual = ref<number[][]>([])

    const devResFilter = computed(() => {
      const q: unknown[] = []
      q.push({ unit: props.unit.name })
      return q.length > 0
        ? JSON.stringify({
          $and: q,
        })
        : ''
    })
    onMounted(async () => {
      await Promise.all([
        fetchSteps({
          session: sessionId,
          unit: unitId,
          pageSize: -1,
        }),
        fetchDevSteps({
          session: sessionId,
          unit: unitId,
          pageSize: -1,
          filter: JSON.stringify({
            deviation: { $exists: true },
          }),
        }),
        fetchDevRes({
          pageSize: -1,
          filter: devResFilter.value,
        }),
      ])
      // console.log(foundSteps.value)
      // console.log(foundDevSteps.value)
      const micros = Object.values(steps.value)
      const macro = Object.keys(steps.value)
      // console.log(steps.value)
      // console.log(OutOfOrder.value)
      // console.log(Missing.value)
      for (let i = 0; i < micros.length; i++) {
        const statics = ref<number[]>([])
        const outCnt = ref(0)
        const missCnt = ref(0)
        if (macro[i] in OutOfOrder.value) {
          const c = OutOfOrder.value[macro[i]]
          outCnt.value += c.length
        }
        if (macro[i] in Missing.value) {
          const c = Missing.value[macro[i]]
          missCnt.value += c.length
        }
        const t = micros[i] as Array<number>
        statics.value.push(t.length - outCnt.value - missCnt.value)
        statics.value.push(outCnt.value)
        statics.value.push(missCnt.value)
        // store.value.set(macro[i], statics.value)
        store.value.push(statics.value)
        // manual.value.push(statics.value)
      }
      // console.log(manual.value)
      if (foundDevRes.value[0]) {
        earlyStart.value = foundDevRes.value[0].earlyStart || false
        earlyStop.value = foundDevRes.value[0].earlyStop || false
        multipleUnits.value = foundDevRes.value[0].multipleUnits || false
        additionalNote.value = foundDevRes.value[0].additionalNote || ''
        const steps = foundDevRes.value[0].steps
        if (steps) {
          for (let i = 0; i < steps.length; i++) {
            const step = [Number(steps[i].notDeviated), Number(steps[i].outOfOrder), Number(steps[i].missing)]
            manual.value.push(step)
          }
        }
      } else {
        manual.value = JSON.parse(JSON.stringify(store.value))
      }
      // console.log(steps.value)
    })

    return {
      steps,
      store: store.value,
      manual,
      onSubmit,
      multipleUnits,
      earlyStart,
      earlyStop,
      additionalNote,
      foundDevRes,
    }
  },
})
