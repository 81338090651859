<template>
  <div class="video-missing">
    <div class="video-missing__message">Video is not uploaded.<br>Please, transfer the video</div>
  </div>
</template>

<style lang="sass">
@use '@/styles/abstracts' as abs

.video-missing
  display: flex
  align-items: center
  justify-content: center
  background: abs.$colors-gray-1
  min-height: 17.5rem

  &__message
    font-size: abs.$fonts-large
    color: abs.$colors-light
    text-align: center
</style>
