
import { AiUnit } from '@/api/units'
import { defineComponent, onBeforeMount, ref, PropType } from 'vue'
import { API } from 'aws-amplify'

export default defineComponent({
  name: 'TransferredIndicator',
  props: {
    unit: {
      required: true,
      type: Object as PropType<AiUnit>,
    },
    factory: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const transferred = ref(false)
    const fileName = ((props.unit?.unitVideos ?? [])[0] ?? '').slice(1)

    function checkData(ID: string, factory:string) {
      const params = new URLSearchParams()
      params.append('factory', factory)
      params.append('ID', ID)
      API.get('pmaCloudDashboardApi', '/service/transferred', params)
        .then(response => {
          if (response) {
            transferred.value = true
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    onBeforeMount(async () => {
      await Promise.all([
        checkData(fileName, props.factory),
      ])
    })
    return { transferred }
  },
})
