import { defineStore } from 'pinia'

import { useUsersStore } from '@/stores/users/users'

import type {
  UnitsQueryStoreState,
  CycleTimeFilter,
  CycleTimeFilterMinMax,
} from './types'
import { ReportedIssueErrorCode } from '@/api/steps'
import { ReportedIssueErrorCode as UnitUnitIssueErrorCode } from '@/api/units'

const toTimestamp = (date: string): number => new Date(date).valueOf() / 1000

const ERROR_FILTER_MAP = {
  UNKNOWN: 0,
  NO_ISSUES: 100,
  NO_ISSUES_TIME_AUTO_COMPLETE: 101,
  USER_FORCE_COMPLETE: 200,
  FORCE_TIME_AUTO_COMPLETE: 201,
  FALSE_POSITIVE: 300,
}

const SORT_BY_OPTIONS = {
  DATE_TIME: 'dateTime',
  CYCLE_TIME: 'cycleTime',
  DEVIATIONS_COUNT: 'deviationCnt',
}

const SORT_DIRECTIONS = {
  ASCENDING: 'ascending',
  DESCENDING: 'descending',
}

const UNITS_DISPLAY_MODES = {
  CARDS: 'cards',
  TABLE: 'table',
}

const FILTER_BY_DEVIATIONS = {
  BOTH: 'both',
  WITHOUT_DEVIATIONS: 'without-deviations',
  WITH_DEVIATIONS: 'with-deviations',
}

const usersStore = useUsersStore()

export const useUnitsQueryStore = defineStore('unitsQueryStore', {
  state: (): UnitsQueryStoreState => ({
    displayMode: UNITS_DISPLAY_MODES.CARDS,

    unitName: '',
    stationName: '',
    factoryName: '',

    sortBy: 'dateTime',
    sortByDirection: 'descending',

    dateStart: '',
    dateEnd: '',

    cycleTimeStart: 0,
    cycleTimeEnd: 43200,

    cycleTimeMin: 0,
    cycleTimeMax: 43200,

    procedure: '',

    filterByDeviations: FILTER_BY_DEVIATIONS.BOTH,
    deviations: '',
    errorFilter: {},
    manufacturerFilter: {},
  }),

  getters: {
    isCardsDisplayMode: (state) => state.displayMode === UNITS_DISPLAY_MODES.CARDS,
    isTableDisplayMode: (state) => state.displayMode === UNITS_DISPLAY_MODES.TABLE,

    isDescendingSort: (state) => state.sortByDirection === SORT_DIRECTIONS.DESCENDING,

    unitsFiltersQuery: (state) => {
      const userGroup = usersStore.userGroup
      const unitsFiltersQuery = []
      const manufacturerKeys = Object.keys(state.manufacturerFilter || {})
      if (manufacturerKeys.length && Array.isArray(userGroup)) {
        const manufacturerFilter = manufacturerKeys.filter((item) => {
          return state.manufacturerFilter[item]
        })
        if (manufacturerFilter.length) {
          unitsFiltersQuery.push({ manufacturer: { $in: manufacturerFilter } })
        } else {
          unitsFiltersQuery.push({ manufacturer: { $in: userGroup } })
        }
      } else if (userGroup) {
        unitsFiltersQuery.push({ manufacturer: { $in: userGroup } })
      }
      if (state.unitName) unitsFiltersQuery.push({ name: { $regex: state.unitName } })
      if (state.dateStart) unitsFiltersQuery.push({ 'created.seconds': { $gte: toTimestamp(state.dateStart) } })
      if (state.dateEnd) unitsFiltersQuery.push({ 'created.seconds': { $lte: toTimestamp(state.dateEnd) } })
      if (state.cycleTimeStart > 0) unitsFiltersQuery.push({ 'cycleTime.seconds': { $gte: state.cycleTimeStart } })
      if (state.cycleTimeEnd > 0) unitsFiltersQuery.push({ 'cycleTime.seconds': { $lte: state.cycleTimeEnd } })
      if (state.procedure) unitsFiltersQuery.push({ procedure: { $regex: state.procedure } })
      if (state.stationName) unitsFiltersQuery.push({ station: { $regex: state.stationName } })
      const keys = Object.keys(state.errorFilter || {})
      if (keys.length) {
        const errorFilterArr: Array<typeof ReportedIssueErrorCode[keyof typeof ReportedIssueErrorCode] | undefined> = keys.map((item) => {
          const key = item as keyof typeof UnitUnitIssueErrorCode
          // FIXME: not supposed to be here. Issue - state.errorFilter has {} type instead of set one
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return state.errorFilter[key] ? ReportedIssueErrorCode[key] : undefined
        })
        const errorFilterCodesArr = errorFilterArr.reduce((memo, item) => {
          if (item) {
            memo.push(ERROR_FILTER_MAP[item])
          }
          return memo
        }, [] as number[])
        if (errorFilterCodesArr.length) {
          unitsFiltersQuery.push({
            'error.code': { $in: errorFilterCodesArr },
          })
        }
        // TODO $nin logic since you can't filter by UNKNOWN ??
        // TODO the same logic for URL purposes?
        // TODO display errors in the unit
        // TODO display errors in the step
      }
      // if (state.factoryName) unitsFiltersQuery.push({ source: { $regex: state.factoryName } })

      if (state.filterByDeviations === FILTER_BY_DEVIATIONS.WITHOUT_DEVIATIONS) unitsFiltersQuery.push({ deviationCount: { $exists: false } })
      if (state.filterByDeviations === FILTER_BY_DEVIATIONS.WITH_DEVIATIONS) {
        unitsFiltersQuery.push({ deviationCount: { $exists: true } })
        if (state.deviations) unitsFiltersQuery.push({ deviationCount: +state.deviations })
      }

      if (unitsFiltersQuery.length > 0) return JSON.stringify({ $and: unitsFiltersQuery })
      return ''
    },

    unitsFiltersSortBy: (state) => {
      const isDescendingSort = state.sortByDirection === SORT_DIRECTIONS.DESCENDING

      const isSortedByDateTime = state.sortBy === SORT_BY_OPTIONS.DATE_TIME
      const isSortedByCycleTime = state.sortBy === SORT_BY_OPTIONS.CYCLE_TIME
      const isSortedByDeviationsCount = state.sortBy === SORT_BY_OPTIONS.DEVIATIONS_COUNT

      if (isSortedByDateTime) {
        if (isDescendingSort) return '-created.seconds,-created.nanos'
        return 'created.seconds,created.nanos'
      }

      if (isSortedByCycleTime) {
        if (isDescendingSort) return '-cycleTime.seconds,-cycleTime.nanos'
        return 'cycleTime.seconds,cycleTime.nanos'
      }

      if (isSortedByDeviationsCount) {
        if (isDescendingSort) return '-deviationCount'
        return 'deviationCount'
      }

      return ''
    },
  },

  actions: {
    setFilterByDeviations(payload: string) {
      this.filterByDeviations = payload.toLowerCase()
    },

    setDisplayMode(payload: string) {
      this.displayMode = payload.toLowerCase()
    },

    setUnitNameFilter(payload: string) {
      this.unitName = payload.toLowerCase()
    },

    setStationNameFilter(payload: string) {
      this.stationName = payload.toLowerCase()
    },

    setFactoryNameFilter(payload: string) {
      this.factoryName = payload.toLowerCase()
    },

    setUnitsSortByFilter(payload: string) {
      this.sortBy = payload
    },

    setSortByDirection(payload: string) {
      const isGivenDirectionAvailable = Object.values(SORT_DIRECTIONS).includes(payload)
      if (!isGivenDirectionAvailable) return

      this.sortByDirection = payload
    },

    toggleSortByDirection() {
      const isDescendingSort = this.sortByDirection === SORT_DIRECTIONS.DESCENDING
      if (isDescendingSort) {
        this.sortByDirection = SORT_DIRECTIONS.ASCENDING
      } else {
        this.sortByDirection = SORT_DIRECTIONS.DESCENDING
      }
    },

    setDateStartFilter(payload: string) {
      this.dateStart = payload
    },

    setDateEndFilter(payload: string) {
      this.dateEnd = payload
    },

    setCycleTimeFilter(payload: CycleTimeFilter) {
      this.cycleTimeStart = payload.from
      this.cycleTimeEnd = payload.to
    },

    setCycleTimeFilterStart(payload: number) {
      this.cycleTimeStart = payload
    },

    setCycleTimeFilterEnd(payload: number) {
      this.cycleTimeEnd = payload
    },

    setCycleTimeMinMax(payload: CycleTimeFilterMinMax) {
      this.cycleTimeMin = payload.min
      this.cycleTimeMax = payload.max
    },

    setUnitsProcedureFilter(payload: string) {
      this.procedure = payload
    },

    setDeviationsFilter(payload: string) {
      this.deviations = payload
    },

    clearFilters() {
      this.unitName = ''
      this.stationName = ''
      this.factoryName = ''

      this.sortBy = 'dateTime'
      this.sortByDirection = 'descending'

      this.dateStart = ''
      this.dateEnd = ''

      this.cycleTimeStart = 0
      this.cycleTimeEnd = 43200

      this.cycleTimeMin = 0
      this.cycleTimeMax = 43200

      this.procedure = ''

      this.deviations = ''
      this.errorFilter = {}
      this.manufacturerFilter = {}
    },
    updateErrorFilterValue(name: keyof typeof ReportedIssueErrorCode, value: boolean | string) {
      // FIXME: not supposed to be here. Issue - this propery isn't read-only and should be filled with selected filters
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.errorFilter[name] = !this.errorFilter[name]
    },
    updateManufacturerFilterValue(userGroup: string) {
      this.manufacturerFilter[userGroup] = !this.manufacturerFilter[userGroup]
    },
  },
})
