import { AiDevResolution } from '@/api/dev_resolution'
import { NfluxaiStep } from '@/api/steps'

export interface StepWithDeviation {
  stepId: string,
  notDeviated: number,
  outOfOrder: number,
  missing: number,
}

export interface StepsAsDevResolutions {
  unit?: string,
  steps: StepWithDeviation[],
}

const ERROR_MESSAGES = {
  NO_ERRORS: 'No errors',
  NOT_AUDITED: 'Not audited yet',
  OPERATOR_ERROR: 'Operator Error',
  AI_ERROR: 'AI Error',
  AI_AND_OPERATOR_ERROR: 'AI & Operator error',
}

export const getErrorReason = (stepsForUnit?: StepsAsDevResolutions, unitDevResolution?: AiDevResolution): string => {
  if (!stepsForUnit || !unitDevResolution) return ERROR_MESSAGES.NOT_AUDITED

  const areBaseStepsWithoutErrors = stepsForUnit.steps.every(step => step.notDeviated >= 0 && step.missing <= 0 && step.outOfOrder <= 0)
  if (areBaseStepsWithoutErrors) return ERROR_MESSAGES.NO_ERRORS

  const areBaseStepsAndDevResolutionSame = stepsForUnit.steps.every(step => {
    const { stepId, notDeviated, outOfOrder, missing } = step
    const devResolutionStep = unitDevResolution.steps?.find(devStep => devStep.stepId === stepId)
    if (!devResolutionStep) return false

    const {
      notDeviated: devResolutionNotDeviated,
      missing: devResolutionMissing,
      outOfOrder: devResolutionOutOfOrder,
    } = devResolutionStep

    return notDeviated === devResolutionNotDeviated && outOfOrder === devResolutionOutOfOrder && missing === devResolutionMissing
  })

  if (areBaseStepsAndDevResolutionSame) {
    return ERROR_MESSAGES.OPERATOR_ERROR
  } else {
    let operatorError = false
    let aiError = false

    for (let i = 0; i < stepsForUnit.steps.length; i++) {
      const baseStep = stepsForUnit.steps[i]
      const deviationStep = unitDevResolution.steps?.find(step => step.stepId === baseStep.stepId)
      if (!deviationStep) continue

      const isStepAndResolutionSame = baseStep.notDeviated === deviationStep.notDeviated &&
        baseStep.missing === deviationStep.missing &&
        baseStep.outOfOrder === deviationStep.outOfOrder

      if (isStepAndResolutionSame) continue

      const isErrorOnAiSide = deviationStep.missing && deviationStep.outOfOrder && (baseStep.missing < deviationStep.missing || baseStep.outOfOrder < deviationStep.outOfOrder)
      const isErrorOnBothSides = deviationStep.notDeviated && (baseStep.notDeviated < deviationStep.notDeviated)

      if (isErrorOnAiSide) aiError = true
      if (isErrorOnBothSides) {
        aiError = true
        operatorError = true
      }
    }

    // Case when source of error is AI only
    if (aiError && !operatorError) return ERROR_MESSAGES.AI_ERROR
    // Case when source of error is Operator only
    if (!aiError && operatorError) return ERROR_MESSAGES.OPERATOR_ERROR
    // Case when both operator and AI made an error
    if (aiError && operatorError) return ERROR_MESSAGES.AI_AND_OPERATOR_ERROR
  }

  return ERROR_MESSAGES.NOT_AUDITED
}

export const transformStepsIntoDevResolutions = (unitsSteps: NfluxaiStep[]): StepsAsDevResolutions[] | undefined => {
  if (!unitsSteps.length) return []

  const formattedUnitsWithSteps = []

  // For-loop takes less memory and runtime
  // because it's JS lang instruction, not array method as .forEach
  for (let unitIndex = 0; unitIndex < unitsSteps.length; unitIndex++) {
    const steps = unitsSteps[unitIndex] as NfluxaiStep[]
    if (!steps.length) continue

    const unitObject: StepsAsDevResolutions = {
      unit: steps[0].name,
      steps: [],
    }

    const stepsWithDeviations: StepWithDeviation[] = []

    for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
      const step = steps[stepIndex]
      const { numberMacro, numberMicro, status } = step
      if (!numberMacro || !numberMicro || (numberMacro <= 0 && numberMicro <= 0)) continue

      let notDeviated = 0
      let missing = 0
      let outOfOrder = 0

      const hasDeviation = !!step.deviation
      const isNotDeviatedStatus = status === 'COMPLETE' && !hasDeviation
      const isOutOfOrderStatus = (status === 'ACTIVE' || status === 'COMPLETE') && hasDeviation
      const isMissingStatus = status === 'INCOMPLETE' && hasDeviation

      if (isNotDeviatedStatus) notDeviated++
      if (isOutOfOrderStatus) outOfOrder++
      if (isMissingStatus) missing++

      const isMacroStepInArray = stepsWithDeviations.some(stepWithDeviation => stepWithDeviation.stepId === String(numberMacro))
      if (isMacroStepInArray) {
        const macroStepIndex = stepsWithDeviations.findIndex(stepsWithDeviations => stepsWithDeviations.stepId === String(numberMacro))
        stepsWithDeviations[macroStepIndex].notDeviated += notDeviated
        stepsWithDeviations[macroStepIndex].outOfOrder += outOfOrder
        stepsWithDeviations[macroStepIndex].missing += missing
      } else {
        stepsWithDeviations.push({
          stepId: String(numberMacro),
          notDeviated,
          outOfOrder,
          missing,
        })
      }
    }

    unitObject.steps = stepsWithDeviations

    formattedUnitsWithSteps.push(unitObject)
  }

  return formattedUnitsWithSteps
}
